import React from "react"

import SEO from "../../components/Seo"
import Layout from "../../components/Layout"
import NavigationBar from "../../components/NavigationBar"
import successStoryOne from "../../images/success-story-one.png"
import successStoryTwo from "../../images/success-story-two.png"
import "./style.scss"

const SuccessStories = () => (
  <Layout>
    <SEO title="Success Stories" />
    <div className="successStoriesContainer">
      <NavigationBar />
      <div className="banner">
        <div className="container">
          <h1>Success Stories</h1>
          <p className="summary">Real people. Real results.</p>
        </div>
      </div>
      <div className="mainContent">
        <div className="container">
          <div className="videos">
            <div className="row">
              <div className="video">
                <a
                  href="https://vimeo.com/455085548"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={successStoryOne} alt="Success Story" />
                </a>
              </div>
              <div className="video">
                <a
                  href="https://vimeo.com/451589865"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={successStoryTwo} alt="Success Story" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default SuccessStories
